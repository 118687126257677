<template>
  <div class="container">
    <div class="banner">
      <img src="../../assets/images/topic/lszt-img1.png" style="width:100%;height:auto;">
    </div>
    <div class="content">
      <div class="title">
        <div class="h1">
          <img src="../../assets/images/topic/lszt-img18.png">
        </div>
      </div>
      <p class="info">
        龙胜名特优数字展销中心体验中心展厅划分五大数字展示空间，分别对应不同主题与功能，以沉浸体验馆为核心，为游客呈现龙胜5大核心景观，互动体验，沉浸式感受；并展示龙胜各类地方特产。兼具线上互动、5G直播、产品展销、文化解读、会客大厅等多种功能。各区块根据其不同功能与空间条件，设计不同的表现形式。</p>
      <div class="imgBox mb50">
        <img src="../../assets/images/topic/lszt-img3.png">
      </div>
      <div class="imgWord mb50">
        <div class="text">
          <p>
            龙胜名特优数字展销中心体验中心展厅划分五大数字展示空间，分别对应不同主题与功能，以沉浸体验馆为核心，为游客呈现龙胜5大核心景观，互动体验，沉浸式感受；并展示龙胜各类地方特产。兼具线上互动、5G直播、产品展销、文化解读、会客大厅等多种功能。各区块根据其不同功能与空间条件，设计不同的表现形式。</p>
        </div>
        <div class="imgBox">
          <img src="../../assets/images/topic/lszt-img4.png">
        </div>
      </div>
      <div class="imgBox mb50">
        <img src="../../assets/images/topic/lszt-img5.png">
      </div>
      <div class="title">
        <div class="h2">
          <img src="../../assets/images/topic/lszt-img19.png">
        </div>
        <div class="desc">动画3件，动漫2套</div>
      </div>
      <div class="subTitle mb40">
        <div class="box">人物设计</div>
      </div>
    </div>
    <div class="imgList">
      <div class="box" v-for="(item,index) in imgList" :key="index" @click="handleDialog(item,index)">
        <div class="img">
          <img :src="require('../../assets/images/topic/img0'+(index+1)+'.png')">
          <div class="text">{{ item.name }}</div>
        </div>

      </div>

    </div>
    <div class="content">
      <div class="subTitle mb40">
        <div class="box">动画三件</div>
      </div>
      <div class="video mb40">
        <div class="item">
          <div class="img">
            <div class="play" :style="{backgroundImage:'url('+require('../../assets/images/topic/img11.png')+')'}">
<!--              <div class="playBtn"></div>-->
            </div>
            <div class="text">
              序厅大屏裸眼3D动画
            </div>
          </div>

        </div>
        <div class="item">
          <div class="img">
            <div class="play" :style="{backgroundImage:'url('+require('../../assets/images/topic/img10.png')+')'}">
<!--              <div class="playBtn"></div>-->
            </div>
            <div class="text">
              寻找龙胜剧情动画
            </div>
          </div>

        </div>
        <div class="item">
          <div class="img">
            <div class="play" :style="{backgroundImage:'url('+require('../../assets/images/topic/img09.png')+')'}">
<!--              <div class="playBtn"></div>-->
            </div>
            <div class="text">
              龙胜四大场景漫游
            </div>
          </div>
        </div>
      </div>
      <div class="views">
        <div class="item">
          <div class="img">
            <img src="../../assets/images/topic/lszt-img6.png">
          </div>
          <div class="text">温泉场景</div>
        </div>
        <div class="item">
          <div class="img">
            <img src="../../assets/images/topic/lszt-img7.png">
          </div>
          <div class="text">梯田场景</div>
        </div>
        <div class="item">
          <div class="img">
            <img src="../../assets/images/topic/lszt-img8.png">
          </div>
          <div class="text">动画两个位于VR设备内</div>
        </div>
      </div>
      <div class="subTitle">
        <div class="box">产品研发</div>
      </div>
      <div class="title">
        <div class="desc">5G直播间及其他区域展示</div>
      </div>

      <div class="views" style="margin:0;">
        <div class="item">
          <img src="../../assets/images/topic/lszt-img9.png">
        </div>
        <div class="item">
          <img src="../../assets/images/topic/lszt-img10.png">
        </div>
        <div class="item">
          <img src="../../assets/images/topic/lszt-img11.png">
        </div>
      </div>
    </div>
    <div class="maskLayer" v-if="dialog!==null">
        <div class="imgBox">
            <div class="close" @click="dialog=null"></div>
            <img :src="dialog.imgUrl">
            <div class="desc">
                <div class="sTitle">
                  {{dialog.title}}
                </div>
                <div class="page">
                   {{dialog.desc}}
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      dialog:null,
      imgList: [
        {
          name: '罗汉果',
          desc: '罗汉果服装融合了汉服的长袍宽袖特点，在此基础上做了镂空和曲线褶皱设计，使整体形象更加活泼灵动，符合人物特点，色调参考了罗汉果本身的暖黄色调，暖色花纹点缀在衣服上，搭配一头棕色饱满的头发和褐色瞳孔，简直就是一只刚刚破壳而出的罗汉果精灵。'
        },
        {
          name: '龙脊茶',
          desc: '龙脊茶服装上衣衣襟，下摆及下身装束均参考壮族女子服夸张处理，让形象更加可爱，服装整体颜色偏深，故增大浅色着色，增加形象的轻盈感，发梢点缀茶草叶片，眼睛的绿色，更贴近茶精灵身份饰，袖口做了发型面积，用饰以晶莹剔透。'
        },
        {
          name: '绿草',
          desc: '绿草服装颜色，纹饰，腰带等均参考苗族男孩服装，衣袖处做了夸张处理，使得整体更加飘逸可爱，更倾向国风人物。'
        },
        {
          name: '凤瑶',
          desc: '服装参考瑶族服饰，纹饰衣襟，腿部绑带，裙子前方衣摆，颜色等贴近瑶族女子服装。裙子和衣摆处做了夸张化处理，让服装更具有少女感，精灵发型上的辫子是对凤鸡尾部形象的艺术化处理，头顶的一缕红色呆毛是对鸡冠部分的艺术化展现，让整体形象更加软萌可爱。'
        },
        {name: '龙椒', desc: '龙椒上衣样式，领口，马甲，裤子，颜色等均参考壮族男子服饰，袖口做了夸张化处理，使得形象更加可爱年轻化，颜色选用红色而规避绿色凸显辣椒红红火火的感觉。'},
        {
          name: '小红糯',
          desc: '小红糯服装衣襟处样式元素均参考侗族女子服故将服装整体调整为稻物头饰是一串稻穗，使腰带，裙子处设计，腿部绑带及袖口样式，考虑到原服装猪肝色颜色太深，的主色调--黄色，让整体更加轻盈，人象更符合稻谷精灵人设。'
        },
        {
          name: '灵水',
          desc: '灵水服装样式，腰带，裙摆，服装纹饰纹饰等均参考红瑶族女子服饰上衣做双层宽袖改变，视觉效果更灵动，更少女。颜色选用蓝色为主色调，使人物形象更加贴近水精灵的人物设定，更加温润轻盈。头饰由裙摆上的花纹演变而来，增加俏皮可爱的少女感。'
        },
        {
          name: '翠鸭',
          desc: '翠鸭服饰的上衣，裤子，腰带花纹均参考传统苗族男子服饰，颜色为了与女子服饰的红色调做区别，采用了黄绿色调，选用了“翠鸭”身上特有的黑色和绿色，使精灵形象更贴近其本体。整体还是以深色底色为主。下装部分增加了苗族纹样裙摆，与基本款裤子做出区别，更有设计性和趣味性。'
        }]
    }
  },
  methods:{
    handleDialog(data,index){
      // this.dialog.show = true
      this.dialog = new Object()
      this.dialog['title'] = data.name
      this.dialog['desc'] = data.desc
      this.dialog['imgUrl']= require('../../assets/images/topic/lszt-img3'+(index)+'.png')
      console.log(this.dialog)
    }
  }
}
</script>

<style scoped lang="less">
@font-face {
  font-family: "syst";
  src: url("../../assets/font/SOURCEHANSERIFCN-REGULAR.OTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.maskLayer{
   position:fixed;
   top:0;
   left:0;
   right:0;
   bottom:0;
   background: rgba(0,0,0,0.7);
   .imgBox{
      width:1306px;
      height:789px;
      position:absolute;
      left:50%;
      top:50%;
      margin:-394.5px 0 0 -653px;
      img{
         width:100%;
         height:100%;
      }
      .close{
          width:36px;
          height:36px;
          background: url("../../assets/images/topic/lszt-img20.png") no-repeat;
          background-size: contain;
          position:absolute;
          right:-40px;
          top:-30px;
          cursor: pointer;
      }
      .desc{
          width:530px;
          height:400px;
          position:absolute;
          right:36px;
          top:44px;
          display:flex;
          flex-direction: column;
          justify-content: center;
          .sTitle{
              width:203px;
              height:50px;
              background: url("../../assets/images/topic/lszt-img14 2.png") no-repeat;
              background-size: contain;
              font-size:30px;
              color:#816334;
              font-family: syst;
              display:flex;
              justify-content: center;
              align-items: center;
              margin-bottom:20px;
          }
          .page{
             font-size:16px;
             line-height:1.9em;
          }
      }
   }
}


.container {
  width: 100%;
  min-height: 100vh;
  background: url("../../assets/images/topic/lszt-img2.jpg") no-repeat top center;
  background-size: cover;
  color: #333;
  font-size: 16px;

  .banner {
    margin-bottom: 40px;
  }

  .imgList {
    width: 100%;
    padding: 0 40px;
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 40px;

    .box {
      width: 25%;
      padding: 0 10px;
      height: 479px;
      margin-bottom: 23px;

      .img {
        width: 100%;
        height: 100%;
        display: flex;
        cursor: pointer;
        flex-direction: column;
        background: url("../../assets/images/topic/lszt-img12.png") no-repeat;
        padding: 16px;
        background-size: contain;

        .text {
          font-size: 20px;
          text-align: center;
          padding-top: 18px;
        }

        img {
          width: 100%;
          flex: 1;
        }
      }
    }
  }

  .content {
    width: 1280px;
    height: auto;
    margin: 0 auto;

    .mb50 {
      margin-bottom: 46px;
    }

    .mb40 {
      margin-bottom: 38px;
    }

    .video {
      width: 100%;
      height: auto;
      display: flex;

      .item {
        width: 33.3333%;
        padding: 0 12px;

        .img {
          width: 100%;
          height: 368px;
          background: url("../../assets/images/topic/lszt-img13.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          padding: 16px;

          .play {
            width: 100%;
            flex: 1;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            display: flex;
            justify-content: center;
            align-items: center;

            .playBtn {
              width: 75px;
              height: 75px;
              background: url("../../assets/images/topic/lszt-img15.png") no-repeat;
              background-size: contain;
              cursor: pointer;
            }
          }

          .text {
            padding-top: 18px;
            font-size: 20px;
            line-height: 1.2;
            text-align: center;
          }
        }
      }
    }

    .views {
      width: 100%;
      height: auto;
      display: flex;
      margin-bottom: 45px;
      min-height: 300px;

      .item {
        width: 33.3333%;
        height: auto;
        padding: 0 12px;

        .text {
          text-align: center;
          font-size: 20px;
          padding-top: 10px;
        }

        img {
          width: 100%;
          height: 243px;
        }
      }
    }

    .subTitle {
      width: 100%;
      height: 50px;
      background: url("../../assets/images/topic/lszt-img14.png") no-repeat center center;
      line-height: 50px;
      text-align: center;
      color: #816334;
      font-size: 30px;
      background-size: contain;
      font-family: syst;
      margin-bottom: 30px;
    }

    .title {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 35px;

      .h1 {
        width: 1151px;
        height: auto;
      }

      .h2 {
        width: 684px;
        height: auto;
      }

      .desc {
        font-size: 20px;
        color: #795a28;
        font-family: syst;
      }


      img {
        width: 100%;
        height: auto;
      }
    }

    .imgWord {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        width: 557px;
        height: auto;
        margin-right: 108px;

        p {
          line-height: 2.2em;
        }
      }

      .imgBox {
        flex: 1;
      }
    }

    .imgBox {
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }

    p {
      line-height: 2em;

      &.info {
        margin-bottom: 50px;
      }
    }
  }
}
</style>