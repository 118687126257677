var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"imgList"},_vm._l((_vm.imgList),function(item,index){return _c('div',{key:index,staticClass:"box",on:{"click":function($event){return _vm.handleDialog(item,index)}}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require('../../assets/images/topic/img0'+(index+1)+'.png')}}),_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.name))])])])}),0),_c('div',{staticClass:"content"},[_vm._m(2),_c('div',{staticClass:"video mb40"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"img"},[_c('div',{staticClass:"play",style:({backgroundImage:'url('+require('../../assets/images/topic/img11.png')+')'})}),_c('div',{staticClass:"text"},[_vm._v(" 序厅大屏裸眼3D动画 ")])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"img"},[_c('div',{staticClass:"play",style:({backgroundImage:'url('+require('../../assets/images/topic/img10.png')+')'})}),_c('div',{staticClass:"text"},[_vm._v(" 寻找龙胜剧情动画 ")])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"img"},[_c('div',{staticClass:"play",style:({backgroundImage:'url('+require('../../assets/images/topic/img09.png')+')'})}),_c('div',{staticClass:"text"},[_vm._v(" 龙胜四大场景漫游 ")])])])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),(_vm.dialog!==null)?_c('div',{staticClass:"maskLayer"},[_c('div',{staticClass:"imgBox"},[_c('div',{staticClass:"close",on:{"click":function($event){_vm.dialog=null}}}),_c('img',{attrs:{"src":_vm.dialog.imgUrl}}),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"sTitle"},[_vm._v(" "+_vm._s(_vm.dialog.title)+" ")]),_c('div',{staticClass:"page"},[_vm._v(" "+_vm._s(_vm.dialog.desc)+" ")])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("../../assets/images/topic/lszt-img1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"h1"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img18.png")}})])]),_c('p',{staticClass:"info"},[_vm._v(" 龙胜名特优数字展销中心体验中心展厅划分五大数字展示空间，分别对应不同主题与功能，以沉浸体验馆为核心，为游客呈现龙胜5大核心景观，互动体验，沉浸式感受；并展示龙胜各类地方特产。兼具线上互动、5G直播、产品展销、文化解读、会客大厅等多种功能。各区块根据其不同功能与空间条件，设计不同的表现形式。")]),_c('div',{staticClass:"imgBox mb50"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img3.png")}})]),_c('div',{staticClass:"imgWord mb50"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v(" 龙胜名特优数字展销中心体验中心展厅划分五大数字展示空间，分别对应不同主题与功能，以沉浸体验馆为核心，为游客呈现龙胜5大核心景观，互动体验，沉浸式感受；并展示龙胜各类地方特产。兼具线上互动、5G直播、产品展销、文化解读、会客大厅等多种功能。各区块根据其不同功能与空间条件，设计不同的表现形式。")])]),_c('div',{staticClass:"imgBox"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img4.png")}})])]),_c('div',{staticClass:"imgBox mb50"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img5.png")}})]),_c('div',{staticClass:"title"},[_c('div',{staticClass:"h2"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img19.png")}})]),_c('div',{staticClass:"desc"},[_vm._v("动画3件，动漫2套")])]),_c('div',{staticClass:"subTitle mb40"},[_c('div',{staticClass:"box"},[_vm._v("人物设计")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subTitle mb40"},[_c('div',{staticClass:"box"},[_vm._v("动画三件")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"views"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img6.png")}})]),_c('div',{staticClass:"text"},[_vm._v("温泉场景")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img7.png")}})]),_c('div',{staticClass:"text"},[_vm._v("梯田场景")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img8.png")}})]),_c('div',{staticClass:"text"},[_vm._v("动画两个位于VR设备内")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subTitle"},[_c('div',{staticClass:"box"},[_vm._v("产品研发")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"desc"},[_vm._v("5G直播间及其他区域展示")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"views",staticStyle:{"margin":"0"}},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img9.png")}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img10.png")}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../../assets/images/topic/lszt-img11.png")}})])])
}]

export { render, staticRenderFns }